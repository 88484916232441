import { NotImplemented } from '@components/notImplemented';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faAnalytics,
    faBarcode,
    faBarcodeAlt,
    faBarcodeScan,
    faBoxes,
    faBug,
    faBullhorn,
    faCalendarAlt,
    faCartCirclePlus,
    faCashRegister,
    faCertificate,
    faClipboardList,
    faCog,
    faDatabase,
    faEye,
    faEyedropper,
    faFileImport,
    faGear,
    faHammer,
    faHistory,
    faInfo,
    faMagnifyingGlass,
    faMap,
    faMapMarkerTimes,
    faNotebook,
    faPersonDolly,
    faQrcode,
    faRocket,
    faScannerGun,
    faShieldCheck,
    faShoppingCart,
    faSquarePollVertical,
    faStore,
    faTags,
    faUsers,
    faWarehouseFull,
} from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/material';
import { filterTruthy } from '@whiz-cart/node-shared/helpers/filterTruthy';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { ComponentType, LazyExoticComponent, lazy } from 'react';
import { useSelector } from 'react-redux';
import { ScopeParameters } from './auth/authTypes';
import { useAuth } from './auth/useAuth';
import RenderError from './error/renderError';
import { TranslationKeys, useTranslator } from './translate';
import { faMeterBolt } from '@fortawesome/pro-regular-svg-icons';

type ClaimParams = string | [string, ...ScopeParameters];

export interface Route {
    exact?: boolean;
    path: string;
    link: string;
    redirect?: string;
    icon?: IconDefinition;
    translationKey?: TranslationKeys;
    label?: string;
    englishLabel?: string;
    group?: string;
    scope?: 'global' | 'store' | 'storeDraft';
    claims?: ClaimParams[] | { global?: ClaimParams[]; store?: ClaimParams[] };
    roles?: string[];
    component?: LazyExoticComponent<any>;
    children?: Route[];
    nestedChildren?: Route[];
    showOnlyWithChildren?: boolean;
    parentInNavigation?: Route;
    nested?: boolean;
}

type RawRoute =
    | (Omit<Route, 'link' | 'component' | 'children' | 'isNested'> & {
          link?: string;
          component?: () => Promise<{ default: ComponentType }>;
          children?: (RawRoute | undefined | null | false | '' | 0)[];
      })
    | false
    | undefined;

async function mapImport<T, K extends keyof T>(importPromise: Promise<T>, map: K): Promise<{ default: T[K] }>;
async function mapImport<T, S>(importPromise: Promise<{ default: T }>, map: (def: T) => S): Promise<{ default: S }>;
async function mapImport<T>(importPromise: Promise<{ default: T }>, map: string | ((def: T) => any)) {
    const comp = await importPromise;
    return typeof map === 'string' ? { ...comp, default: (comp as any)[map] } : { ...comp, default: map(comp.default) };
}

function withProps<P, T extends ComponentType<P> = ComponentType<P>>(importPromise: Promise<{ default: T }>, defaultProps: P) {
    return mapImport(importPromise, (Component: any) => (props: any) => <Component {...defaultProps} {...props} />);
}

function transformRoute(rawRoute: RawRoute, parent?: Route): Route[] {
    if (!rawRoute) {
        return [];
    }

    const route: Route = {
        ...rawRoute,
        path: [parent?.path, rawRoute.path].filter(Boolean).join('/'),
        link: urlService.resolve(parent?.link ?? '/', rawRoute.link ?? rawRoute.path),
        redirect: rawRoute.redirect ? urlService.resolve(parent?.link ?? '/', rawRoute.path, rawRoute.redirect) : undefined,
        scope: rawRoute.scope ?? parent?.scope,
        claims: rawRoute.claims ?? parent?.claims,
        roles: rawRoute.roles ?? parent?.roles,
        component: rawRoute.component && lazy(() => loadComponent(rawRoute.component)),
        parentInNavigation: parent && (!!rawRoute.label || !!rawRoute.translationKey) ? parent : undefined,
        children: undefined,
        showOnlyWithChildren: rawRoute.showOnlyWithChildren ?? false,
    };

    const children = rawRoute.children?.filter(filterTruthy).flatMap((child) => transformRoute(child, route));
    const flatChildren = children?.filter((child) => !child.nested) ?? [];
    route.children = children?.filter((child) => child.parentInNavigation === route);
    route.nestedChildren = children?.filter((child) => child.nested);

    return flatChildren.concat(route);
}

function flattenRoutes(...routes: RawRoute[]): Route[] {
    const flatten = (route: Route): Route[] => [...(route.children?.flatMap(flatten) ?? []), route];
    return routes.flatMap((route) => transformRoute(route)).flatMap(flatten);
}

const routes = [
    ...flattenRoutes(
        // Kunden
        {
            path: '/sm',
            icon: faEye,
            translationKey: 'navigation.sessionMonitor',
            group: 'navigation.customers',
            scope: 'store',
            claims: ['session/read'],
            component: () => import('./sessionMonitor/sessionMonitor.component'),
        },
        {
            path: '/customer/details/:deviceId/:hash/:back',
            component: () => import('./sessionMonitor/customer/customerDetails.component'),
            scope: 'store',
            claims: ['session/read'],
        },
        {
            path: '/checkout',
            component: () => import('./checkout/checkout.component'),
            icon: faCashRegister,
            translationKey: 'navigation.checkout',
            group: 'navigation.customers',
            scope: 'store',
            claims: ['payment/read', 'payment/write'],
            children: [
                {
                    path: 'cartLogin',
                    component: () =>
                        withProps<any>(import('./cartLogin/cartLogin.component'), {
                            back: '/checkout',
                        }),
                },
                {
                    path: 'payment/:sessionGuid/:paymentGuid/:step?/:subStep?',
                    component: () => import('./checkout/payment.component'),
                },
            ],
        },
        {
            path: '/integrityCheck',
            scope: 'store',
            component: () => import('./integrityCheck/integrityCheck.component'),
            children: [
                {
                    path: 'details/:paymentRequestGuid/',
                    scope: 'store',
                    component: () => import('./integrityCheck/integrityCheckDetailsWrapper.component'),
                },
            ],
            claims: ['payment/read', 'payment/write'],
        },
        {
            path: '/session/:sessionGuid?/:paymentGuid?',
            link: '/session',
            component: () => import('./checkout/checkoutHistory.component'),
            icon: faHistory,
            translationKey: 'navigation.sessionHistory',
            group: 'navigation.customers',
            scope: 'store',
            claims: ['payment/read'],
        },
        {
            path: '/cartLogin/',
            group: 'navigation.customers',
            component: () => import('./cartLogin/cartLogin.component'),
            icon: faQrcode,
            translationKey: 'navigation.loginCode',
            scope: 'store',
            claims: ['ui/cartLoginCode'],
        },
        {
            path: '/dsi/inspect/suspicious',
            component: () => import('./dsi/inspector/inspect/dsiInspect.component'),
            claims: ['dsi/read', 'dsi/write'],
        },
        {
            path: '/dsi/inspect/confirm/:sessionGuid/:step?',
            component: () => import('./dsi/inspector/confirm/dsiConfirm.component'),
            claims: ['dsi/read', 'dsi/write'],
        },
        {
            path: '/dsi',
            link: '/dsi',
            group: 'navigation.customers',
            icon: faShieldCheck,
            translationKey: 'navigation.dsi',
            claims: ['dsi/read', 'dsi/write'],
            children: [
                {
                    path: 'live',
                    translationKey: 'navigation.dsiLiveCheck',
                    claims: ['session/imageDetection/read', 'session/imageDetection/confirm'],
                    children: [
                        {
                            path: ':sessionGuid',
                            component: () => import('./dsi/live/session/dsiLiveSessionView.component'),
                        },
                        {
                            path: '',
                            component: () => import('./dsi/live/dsiLiveView.component'),
                            icon: faMagnifyingGlass,
                        },
                    ],
                },
                {
                    path: ':sessionGuid?/:step?',
                    component: () => import('./dsi/overview/dsi.component'),
                },
            ],
        },
        // Artikel
        {
            path: '/productBrowser',
            icon: faBoxes,
            translationKey: 'navigation.productBrowser',
            group: 'navigation.products',
            scope: 'store',
            component: () => import('./productBrowser/productBrowserList.component'),
            claims: ['product/global/read'],
            children: [
                {
                    path: ':itemId',
                    component: () => import('./productBrowser/productBrowserEdit.component'),
                    nested: true,
                },
            ],
        },
        {
            path: '/productLabels/:labelId?',
            link: '/productLabels',
            group: 'navigation.products',
            component: () => import('./product/productLabels/productLabelsOverview'),
            icon: faTags,
            translationKey: 'navigation.productLabels',
            claims: ['product/labels/read'],
        },
        {
            path: '/product/barcodeGenerator',
            link: '/product/barcodeGenerator',
            group: 'navigation.products',
            component: () => import('./barcodeGenerator/barcodeGenerator.component'),
            icon: faBarcodeAlt,
            translationKey: 'navigation.barcodeGenerator',
            claims: ['ui/barcodeGenerator'],
        },
        {
            path: '/product/barcodeScanner',
            link: '/product/barcodeScanner',
            group: 'navigation.products',
            component: () => import('./barcodeScanner/barcodeScanner.component'),
            icon: faScannerGun,
            translationKey: 'product.barcodeScanner',
            claims: ['ui/barcodeScanner'],
        },
        {
            path: '/product/import',
            link: '/product/import',
            group: 'navigation.products',
            component: () => import('./productImport/productImport.component'),
            icon: faFileImport,
            translationKey: 'product.import',
            claims: ['ui/productImport'],
        },
        {
            path: '/product/details',
            group: 'navigation.products',
            component: () => import('./product/productDetails.component'),
            icon: faInfo,
            translationKey: 'navigation.details',
            scope: 'store',
            claims: ['ui/productDetails'],
            children: [
                {
                    path: 'mhdgroup',
                    component: () => import('./product/productDetails.component'),
                    nested: true,
                },
                {
                    path: ':barcode(\\d+)?/:mhdgroup',
                    component: () => import('./product/productDetails.component'),
                    nested: true,
                },
            ],
        },
        {
            path: '/product/mhd',
            link: '/product/mhd',
            group: 'navigation.products',
            component: () => import('./mhd/mhd.component'),
            icon: faCalendarAlt,
            translationKey: 'navigation.mhd',
            scope: 'store',
            claims: ['product/expiration/write'],
        },
        {
            path: '/product/promotions',
            link: '/product/promotions',
            group: 'navigation.products',
            icon: faCertificate,
            translationKey: 'navigation.promotions',
            component: () => import('./promotions/promotions'),
            scope: 'store',
            claims: ['promotion/read', 'promotion/write', 'digitalCoupons/read', 'digitalCoupons/write'],
            children: [
                {
                    claims: ['promotion/read', 'promotion/write'],
                    path: 'voucherPromotion/:promotionGuid',
                    component: () =>
                        withProps(import('./promotions/form/discountsFormContainer.component'), {
                            context: 'voucherPromotion',
                        }),
                    nested: true,
                },
                {
                    claims: ['promotion/read', 'promotion/write'],
                    path: 'kddr/:promotionGuid',
                    component: () =>
                        withProps(import('./promotions/form/discountsFormContainer.component'), {
                            context: 'kddr',
                        }),
                    nested: true,
                },
                {
                    claims: ['digitalCoupons/read'],
                    path: 'easyDeal/:easyDealId',
                    component: () => import('./promotions/form/easyDealDetail'),
                    nested: true,
                },
                {
                    claims: ['recurringCustomerLoyalty/read', 'recurringCustomerLoyalty/write'],
                    path: 'loyaltyRewards/:rewardId',
                    component: () => import('./promotions/form/loyaltyRewardsDetail'),
                    nested: true,
                },
            ],
        },
        {
            path: '/product/nobarcode/',
            scope: 'store',
            icon: faBarcodeScan,
            translationKey: 'navigation.noBarcode',
            group: 'navigation.products',
            claims: ['product/noBarcode/write'],
            component: () => import('./noBarcode/noBarcode.component'),
        },
        {
            path: '/product/notfoundproducts/',
            scope: 'store',
            icon: faMapMarkerTimes,
            translationKey: 'navigation.NotFoundProducts',
            group: 'navigation.products',
            roles: [],
            component: () => import('./product/productsNotFoundNavigation.component'),
        },
        // Technik
        {
            path: '/infrastructure/config',
            group: 'navigation.infrastructure',
            component: () => import('./systemConfig/systemConfig.component'),
            icon: faCog,
            translationKey: 'navigation.settings',
            roles: [],
        },
        {
            path: '/infrastructure/devOps',
            group: 'navigation.infrastructure',
            component: () => import('./devOps/devOps.component'),
            icon: faCog,
            translationKey: 'navigation.devops',
            roles: [],
            children: [
                {
                    path: 'buildMonitor',
                    component: () => import('./buildMonitor/buildMonitor'),
                    icon: faHammer,
                    translationKey: 'navigation.buildMonitor',
                },

                config?.feature?.logAnalyzer && {
                    path: 'logs',
                    component: () => import('@logAnalyzer/logAnalyzer'),
                    icon: faHammer,
                    translationKey: 'navigation.logAnalyzer',
                },

                {
                    path: 'dataSync',
                    icon: faShoppingCart,
                    label: 'DataSync',
                    component: () => import('@devOps/dataSync'),
                },
            ],
        },

        {
            path: '/infrastructure/cart',
            component: () => import('./carts/cartOverview.component'),
            group: 'navigation.infrastructure',
            icon: faShoppingCart,
            translationKey: 'navigation.carts',
            claims: { global: ['cart/global/read'], store: ['cart/read'] },
            children: [
                {
                    path: 'global',
                    component: () => import('./carts/cartGlobal'),

                    children: [
                        {
                            path: 'guid/:cartGuid',
                            component: () =>
                                withProps(import('./carts/cartGlobalDetails'), {
                                    context: 'cart',
                                }),
                        },
                        {
                            path: 'fn/:framenumber',
                            component: () =>
                                withProps(import('./carts/cartGlobalDetails'), {
                                    context: 'cart',
                                }),
                        },
                    ],
                },
                {
                    path: 'store',
                    component: () => import('./carts/cartOverviewList.component'),
                    children: [
                        {
                            path: ':tab/details/:cartId',
                            component: () =>
                                withProps(import('./carts/cart.component'), {
                                    context: 'carts',
                                }),
                            nested: true,
                        },
                    ],
                },
            ],
        },

        {
            path: '/erp',
            redirect: 'plan',
            translationKey: 'erp.title',
            icon: faWarehouseFull,
            group: 'navigation.infrastructure',
            showOnlyWithChildren: true,
            children: [
                {
                    path: 'plan',
                    translationKey: 'erp.plan.navigation',
                    component: () => import('./resourcePlanner/planner'),
                    claims: ['resourcePlanning/read', 'resourcePlanning/write'],
                },
                {
                    path: 'assets',
                    translationKey: 'assets.title',
                    scope: 'storeDraft',
                    component: () => import('./resourcePlanner/assets'),
                    claims: ['assets/read'],
                    children: [
                        {
                            path: ':assetId',
                            component: () => import('./resourcePlanner/assets/assetDetailsView'),
                            nested: true,
                        },
                    ],
                },
                {
                    path: 'props',
                    translationKey: 'erp.props.title',
                    component: () => import('./resourcePlanner/properties'),
                    claims: ['resourceProperties/read', 'resourceProperties/write'],
                    children: [
                        {
                            path: 'new',
                            component: () => import('./resourcePlanner/properties/propertiesEdit'),
                            nested: true,
                        },
                        {
                            path: ':id',
                            component: () => import('./resourcePlanner/properties/propertiesEdit'),
                            nested: true,
                        },
                    ],
                },
            ],
        },

        {
            path: '/chargers',
            translationKey: 'assets.type.chargingOption',
            component: () => import('./resourcePlanner/chargingOptions'),
            scope: 'storeDraft',
            icon: faMeterBolt,
            claims: ['chargingOption/read', 'chargingOption/write'],
            group: 'navigation.infrastructure',
        },

        {
            path: '/infrastructure/setup',
            group: 'navigation.infrastructure',
            icon: faCartCirclePlus,
            translationKey: 'navigation.setup',
            scope: 'store',
            claims: ['cartSetup/initSetup/write'],
            redirect: 'active',
            children: [
                {
                    path: 'active',
                    translationKey: 'navigation.cartsInSetup',
                    component: () => import('./cartsInSetup/cartsInSetup.component'),
                    children: [
                        {
                            path: 'create',
                            component: () => import('./deployment/cartCreate.component'),
                        },

                        {
                            path: 'technicalCodes',
                            component: () => import('./deployment/cartTechnicalCodes.component'),
                        },
                    ],
                },

                {
                    path: 'log/:mac',
                    component: () => import('./cartsInSetup/cartsInSetupLog'),
                },

                {
                    path: 'replacements',
                    translationKey: 'navigation.cartReplacements',
                    component: () => import('./cartReplacements/cartReplacements.component'),
                },

                {
                    path: 'auto',
                    translationKey: 'autoCartSetup.title',
                    component: () => import('./cartsInSetup/autoCartSetup/autoCartSetup'),
                },
            ],
        },

        {
            path: '/infrastructure/deployment',
            redirect: 'list',
            group: 'navigation.infrastructure',
            icon: faRocket,
            translationKey: 'navigation.deployments',
            roles: ['devops', 'rollout'],
            children: [
                {
                    path: 'list',
                    component: () => import('./deployment/deploymentOverview.component'),
                },

                {
                    path: 'cart/:cartId',
                    component: () =>
                        withProps(import('./carts/cart.component'), {
                            context: 'deployment',
                        }),
                },

                {
                    path: 'package',
                    redirect: 'list',
                    translationKey: 'deployment.list.title',
                    roles: ['devops'],
                    children: [
                        {
                            path: 'list/:query?',
                            component: () => import('./deployment/deploymentPackageList.component'),
                        },
                        {
                            path: 'create/:basePackageGuid?',
                            component: () => mapImport(import('./deployment/deploymentPackageCreate.component'), 'DeploymentPackageCreate'),
                        },
                        {
                            path: ':packageGuid',
                            component: () => import('./deployment/deploymentPackageDetails.component'),
                        },
                    ],
                },

                {
                    path: 'promoted',
                    translationKey: 'deployment.promoted.list.title',
                    roles: ['devops'],
                    children: [
                        {
                            path: 'create/:promotedVersionGuid?',

                            component: () => import('./deployment/promotedVersionCreate'),
                        },
                        {
                            path: ':name/:version',
                            component: () => import('./deployment/promotedVersionDetails'),
                        },
                        {
                            path: '',
                            component: () => import('./deployment/promotedVersionList.component'),
                        },
                    ],
                },

                {
                    path: 'groups',
                    translationKey: 'deployment.groups.title',
                    roles: ['devops'],
                    component: () => import('./deployment/deploymentGroups.component'),
                },
            ],
        },
        {
            path: '/infrastructure/defects',
            group: 'navigation.infrastructure',
            component: () => import('./defects/defectOverview'),
            icon: faBug,
            translationKey: 'navigation.defects',
            claims: ['cart/defect/write'],
        },

        //Verwaltung
        {
            path: '/user',
            icon: faUsers,
            translationKey: 'navigation.users',
            group: 'navigation.administration',
            roles: ['storeManager', 'rollout', 'foodAcademy', 'demo'],
            redirect: 'list',
            children: [
                {
                    path: 'list/:query?',
                    component: () => import('./user/userList.component'),
                },
                {
                    path: 'create',
                    component: () => import('./user/userCreate.component'),
                },
                {
                    path: 'edit/:user',
                    component: () => import('./user/userEdit.component'),
                },
            ],
        },
        {
            path: '/customer',
            icon: faPersonDolly,
            translationKey: 'navigation.customers',
            group: 'navigation.administration',
            claims: [['customer/adminAccess', 'global']],
            component: () => import('./customer/customer.component'),
        },
        {
            path: '/information',
            component: () => import('./information/information.component'),
            icon: faInfo,
            translationKey: 'navigation.info',
            group: 'navigation.administration',
            claims: [['ui/information', 'any']],
        },
        {
            path: '/stores',
            icon: faStore,
            translationKey: 'navigation.stores',
            group: 'navigation.administration',
            redirect: 'list',
            claims: [['storeCreation/list', 'any']],
            children: [
                { path: 'list', component: () => import('./store/overview/storeList'), claims: [['storeCreation/list', 'any']] },
                {
                    path: 'create',
                    component: () => import('./store/details/storeCreate'),
                    claims: ['storeCreation/create'],
                },
                {
                    path: 'import',
                    component: () => import('./store/import/storeImport'),
                    claims: ['storeCreation/create'],
                },
                {
                    path: 'details/:milestoneGroup',
                    component: () => import('./store/details/storeMilestoneGroup'),
                    scope: 'storeDraft',
                    claims: ['storeCreation/get'],
                },
                {
                    path: 'details',
                    component: () => import('./store/details/storeDetails'),
                    scope: 'storeDraft',
                    claims: ['storeCreation/get'],
                },
                {
                    path: 'table',
                    component: () => import('./store/overview/storeListTable'),
                    claims: ['storeCreation/list'],
                },
            ],
        },
        {
            path: '/marketing',
            icon: faBullhorn,
            translationKey: 'navigation.marketing',
            group: 'navigation.administration',
            claims: [['prospect/read', 'any']],
            redirect: 'list',
            children: [
                {
                    path: 'list',
                    component: () => import('./marketing/marketing.component'),
                },
                {
                    path: 'details/:prospectId',
                    component: () => import('./marketing/marketingCreate.component'),
                },
                {
                    path: 'create',
                    component: () => import('./marketing/marketingCreate.component'),
                },
            ],
        },
        {
            path: '/surveys',
            icon: faSquarePollVertical,
            translationKey: 'navigation.surveys',
            group: 'navigation.administration',
            claims: ['surveys/read'],
            component: () => import('./surveys/SurveyOverview'),
            children: [
                {
                    path: 'results/:surveyId',
                    component: () => import('./surveys/Survey.component'),
                    nested: false,
                },
                {
                    path: 'edit/:surveyId',
                    component: () => import('./surveys/surveyEdit'),
                    nested: false,
                },
                {
                    path: 'new',
                    component: () => import('./surveys/surveyEdit'),
                    nested: false,
                },
            ],
        },
        {
            path: '/masterData',
            component: () => mapImport(import('./masterData/masterDataOverview'), 'MasterDataOverview'),
            icon: faDatabase,
            translationKey: 'navigation.masterData',
            group: 'navigation.administration',
            claims: ['masterData/all/read', 'masterData/write'],
        },
        {
            path: '/release',
            redirect: 'list',
            icon: faClipboardList,
            translationKey: 'navigation.releaseNotes',
            group: 'navigation.administration',
            claims: [['ui/releaseNotes', 'any']],
            children: [
                {
                    path: 'list/:query?',
                    component: () => mapImport(import('./releaseNotes/releaseNotesList'), 'ReleaseNotesList'),
                },
                {
                    path: 'display/:releaseNotesGuid',
                    component: () => mapImport(import('./releaseNotes/releaseNotesView'), 'ReleaseNotesView'),
                },
                {
                    path: 'edit/:releaseNotesGuid?',
                    component: () => mapImport(import('./releaseNotes/releaseNotesEdit'), 'ReleaseNotesEdit'),
                },
            ],
        },
        {
            path: '/storeSettings/:settingsKey?',
            link: '/storeSettings',
            component: () => import('./storeSettings/storeSettingsOverview.component'),
            icon: faGear,
            translationKey: 'navigation.storeSettings',
            group: 'navigation.administration',
            claims: [['storeSettings/write', 'any']],
        },
        config?.feature?.reporting && {
            path: '/reports/:activeId?',
            link: '/reports',
            component: () => mapImport(import('./reports/reportOverview'), 'ReportsOverview'),
            icon: faAnalytics,
            translationKey: 'navigation.reports',
            group: 'navigation.administration',
            scope: 'store',
            claims: ['reports/token/read'],
        },
        {
            path: '/mapEditor/:systemId?/:view?',
            link: '/mapEditor',
            component: () => mapImport(import('./mapEditor/mapEditorView'), 'MapEditorView'),
            icon: faMap,
            translationKey: 'navigation.mapEditor',
            group: 'navigation.administration',
            scope: 'store',
            claims: ['map/editor/writeBasic'],
        },

        {
            path: '/brandEditor',
            link: '/brandEditor',
            component: () => import('./brandEditor/brandEditor'),
            icon: faEyedropper,
            translationKey: 'brandEditor.title',
            group: 'navigation.administration',
            claims: ['branding/write', 'branding/read'],
            children: [
                {
                    path: 'create',
                    component: () => import('./brandEditor/brandEditorEdit'),
                    nested: true,
                },
                {
                    path: 'edit/:id',
                    component: () => import('./brandEditor/brandEditorEdit'),
                    nested: true,
                },
            ],
        },

        {
            path: '/barcodeClassification',
            component: () => import('./barcodeClassification/bcTable'),
            icon: faBarcode,
            translationKey: 'navigation.barcodeClassification',
            group: 'navigation.administration',
            claims: ['barcodeClassifications/read'],
            children: [
                {
                    path: 'test/:code?',
                    component: () => import('./barcodeClassification/bcTester'),
                    nested: true,
                },
                {
                    path: 'new/:template?',
                    component: () => import('./barcodeClassification/bcForm'),
                    nested: true,
                    claims: ['barcodeClassifications/write'],
                },
                {
                    path: ':id',
                    component: () => import('./barcodeClassification/bcForm'),
                    nested: true,
                    claims: ['barcodeClassifications/write'],
                },
            ],
        },

        {
            path: '/info',
            component: () => import('./supportInfo'),
            icon: faNotebook,
            translationKey: 'supportInfo.title',
            group: 'navigation.administration',
            claims: ['supportInfo/read', 'supportInfo/write'],
            scope: 'storeDraft',
            children: [
                {
                    path: 'sections',
                    component: () => mapImport(import('./supportInfo/supportInfoSections'), 'SupportInfoSectionsDialog'),
                    nested: true,
                },
            ],
        },

        //Account
        {
            path: '/me',
            component: () => import('./user/profile.component'),
        },
    ),
];

export default routes;

async function loadComponent(component?: () => Promise<{ default: ComponentType }>) {
    if (!component) {
        return { default: NotImplemented };
    }

    try {
        return await component();
    } catch (e: any) {
        console.error('Failed to load module:', e, e.response);

        if (!import.meta.env.PROD) {
            return {
                default: () => (
                    <Box sx={{ p: 1 }}>
                        <RenderError error={e} />{' '}
                    </Box>
                ),
            };
        }

        console.warn('Out of date chunks. Reloading the page...');
        window.location.reload();
        return { default: () => null };
    }
}

export function useAccessibleRoutes() {
    const { isLoggedIn, hasClaim, getActiveRoles, isAuthLoading } = useAuth();
    const storeGuid = useSelector((state: any) => state.url.params.storeGuid);
    const stores = useSelector((state: any) => state.stores.stores);

    const filter = (routes: Route[]): Route[] =>
        routes.flatMap((route) => {
            // If route is a store route => don't show without storeGuid set
            if (route.scope === 'store' && !storeGuid) {
                return [];
            }

            // If route is a global route => only show without storeGuid set
            if (route.scope === 'global' && storeGuid) {
                return [];
            }

            if (route.scope === 'store' && !stores[storeGuid]?.storeDbName) {
                // If route is a store route and not draft => don't show if store has no db setup yet
                return [];
            }

            if (route.scope === 'storeDraft' && !storeGuid) {
                return [];
            }

            const routeClaims: [string, any?, any?][] | undefined = (
                Array.isArray(route.claims) ? route.claims : storeGuid ? route.claims?.store : route.claims?.global
            )?.map((claim) => (Array.isArray(claim) ? claim : [claim, storeGuid ? 'store' : 'global']));

            if (routeClaims && !routeClaims.every((claim) => hasClaim(...claim))) {
                return [];
            }

            if (route.roles) {
                const activeRoles = getActiveRoles(storeGuid ? 'store' : 'global');

                if (!activeRoles.has('admin') && !route.roles.some((role) => activeRoles.has(role))) {
                    return [];
                }
            }

            const filteredChildren = route.children && filter(route.children);

            if (route.showOnlyWithChildren && !filteredChildren?.length) {
                return [];
            }

            return [
                {
                    ...route,
                    children: filteredChildren,
                },
            ];
        });

    const accessibleRoutes = isLoggedIn ? filter(routes) : [];
    return { isLoggedIn, accessibleRoutes, isLoading: isAuthLoading };
}

export function useVisibleRoutes() {
    const { accessibleRoutes } = useAccessibleRoutes();
    const t = useTranslator();
    const enT = useTranslator('en');

    const translate = (route: Route): Route => ({
        ...route,
        label: route.translationKey ? (t.unknown(route.translationKey) as string) : route.label,
        englishLabel: route.translationKey ? (enT.unknown(route.translationKey) as string) : undefined,
        children: route.children?.map(translate),
    });

    return accessibleRoutes.filter((route) => route.translationKey && !route.parentInNavigation).map(translate);
}

Object.assign(window, { routes });
